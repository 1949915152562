<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <Label>Aviso n° </Label>
          <input
            disabled
            type="text"
            class="form-control text-left"
            placeholder="Digite"
            v-model="viagem.id"
          />
        </div>
        <div class="col-md-4">
          <Label>Veiculo</Label>
          <multiselect
            disabled
            v-model="select_veiculo"
            :options="veiculos1"
            label="nomeRazao"
          >
          </multiselect>
        </div>
        <div class="col-md-4">
          <Label>Motorista</Label>
          <multiselect
            disabled
            v-model="select_motorista"
            :options="motoristas1"
            label="pes_apelido"
          >
          </multiselect>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-4">
          <Label>*Km inicial do veiculo</Label>
          <input
            @input="formatInput_valor()"
            v-model="viagem.km_inicial"
            type="text"
            class="form-control text-left"
            placeholder="Digite"
          />
        </div>
        <div class="col-md-4">
          <Label>Previsão da Entrega</Label>
          <input
            v-model="viagem.previsao_entrega"
            type="date"
            class="form-control text-left"
            placeholder="Digite"
          />
        </div>
        <div class="col-md-4">
          <Label>Previsão p/ Prestar Contas</Label>
          <input
            v-model="viagem.previsao_conta"
            type="date"
            class="form-control text-left"
            placeholder="Digite"
          />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-4">
          <Label>Ident.</Label>
          <input
            disabled
            v-model="viagem.identificacao"
            type="text"
            class="form-control text-left"
            placeholder="Digite"
          />
        </div>
        <div class="col-md-4">
          <Label>Notas</Label>
          <input
            v-model="viagem.notas"
            type="text"
            class="form-control text-left"
            placeholder="Digite"
          />
        </div>
        <div class="col-md-4">
          <Label>Situação</Label>
          <multiselect
            v-model="select_sit"
            @select="seleciona_sit($event)"
            :options="situacoes"
            label="nome"
            placeholder="Digite"
          ></multiselect>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <Label>Observações:</Label>
          <textarea
            v-model="viagem.obs"
            name=""
            id=""
            cols="30"
            rows="10"
            class="form-control text-left"
          ></textarea>
        </div>
      </div>
      <div class="row mt-4 d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <button class="btn btn-secondary" @click="set_post()">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  props: {
    objeto_atual: { type: Object },
    motoristas: { type: Array },
    veiculos: { type: Array },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      situacoes: [
        {
          id: 1,
          nome: "aguardando a liberação",
        },
        {
          id: 2,
          nome: "Pronto Para o Carregamento",
        },
        {
          id: 3,
          nome: "Carregando",
        },
        {
          id: 4,
          nome: "Carregado",
        },
        {
          id: 5,
          nome: "embarcado",
        },
        {
          id: 6,
          nome: "Entregue",
        },
      ],
      veiculos1: [],
      motoristas1: [],
      viagem: {
        km_inicial: null,
        situacao: null,
        obs: null,
        notas: null,
        previsao_entrega: null,
        previsao_conta: null,
        aviso_embarque_id: null,
        veiculo_id: null,
        motorista_id: null,
        identificacao: null,
      },
      select_motorista: null,
      select_veiculo: null,
      select_sit: null,
    };
  },
  created() {
    this.viagem.aviso_embarque_id = this.objeto_atual.id;

    this.viagem.identificacao = this.objeto_atual.identificacao;
    this.viagem.empresa_id = this.objeto_atual.empresa_id;
    this.motoristas1 = this.motoristas;
    this.veiculos1 = this.veiculos;
  },
  mounted() {
    if (this.objeto_atual.acompamamentos) {
      this.viagem = this.objeto_atual.acompamamentos;
      console.log(this.viagem);
      console.log(this.objeto_atual.acompamamentos);
    }

    // console.log(this.viagem.acompamamentos.previsao_entrega)
    this.inicializar_valores();
  },
  methods: {
    seleciona_sit(evet) {
      this.viagem.situacao = evet.id;
    },
    coverter_float() {
      //typeof(this.pre_ped.valor)
      if (typeof this.viagem.km_inicial == "string") {
        this.viagem2 = this.viagem;
        let stringValor = this.viagem2.km_inicial;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.viagem.km_inicial = valorFlutuante;
      }
    },
    set_post() {
      this.coverter_float();
      let obj_atu = this.objeto_atual;
      this.viagem.obj_atu = obj_atu.prestacao_conta != null ? {...obj_atu.prestacao_conta} : null
      if(this.viagem.obj_atu != null){
        this.viagem.obj_atu.situacao = this.select_sit.id
      }
      if (this.objeto_atual.acompamamentos != undefined) {
        this.$emit("put_viagem", this.viagem);
      } else {
        this.viagem.motorista_id = this.objeto_atual.motorista_id;
        this.viagem.veiculo_id = this.objeto_atual.veiculo_id;
        this.$emit("post_viagem", this.viagem);
      }
    },
    formatInput_valor() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.viagem.km_inicial.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.viagem.km_inicial = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },

    inicializar_valores() {
      let motorista_selecionado = this.motoristas1.find(
        (e) => e.id === this.objeto_atual.motorista_id
      );
      let veiculo_selecionado = this.veiculos1.find(
        (e) => e.id === this.objeto_atual.veiculo_id
      );
      let res = this.situacoes.find((s) => s.id == this.viagem.situacao);
      //
      console.log("Mosntrnado res");
      console.log(res);
      this.select_sit = { ...res };

      // let situacao_selecionada = this.situacoes.find((e) => e.id === this.conta_atual.situacao)
      this.select_motorista = motorista_selecionado;
      this.select_veiculo = veiculo_selecionado;
      this.viagem.km_inicial = veiculo_selecionado.km_atual;
      //   this.select_situacao = situacao_selecionada
      // console.log(this.select_motorista)
      // console.log(this.select_veiculo)
    },
  },
};
</script>

<style>
</style>