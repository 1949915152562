<template>
  <b-modal
    v-model="show_modal"
    size="xl"
    title="Registara despesa"
    hide-footer
    @hidden="back()"
    headerBgVariant="light"
  >
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <Label>Definir Padrão:</Label>
            <multiselect
              v-model="despesa.padrao_id"
              :options="padroes"
              label="nome"
            >
            </multiselect>
          </div>
          <div class="col-md-4">
            <Label>{{ "*Descrição" }}:</Label>
            <input
              v-model="despesa.descricao"
              type="text"
              class="form-control text-left"
            />
          </div>
          <div class="col-md-4">
            <Label>{{ "*Valor" }}:</Label>
            <input
              v-model="despesa.valor"
              @input="formatInputFloat($event, despesa, 'valor')"
              type="text"
              class="form-control text-left"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <Label>{{ "*Especie de Pagamento" }}:</Label>
            <multiselect
              v-model="despesa.pagamento_id"
              :options="vet_pagamentos"
              label="tiponome"
            >
            </multiselect>
          </div>
          <div class="col-md-4">
            <Label>{{ "Vencimento" }}:</Label>
            <input
              v-model="despesa.dt_vencimento"
              type="date"
              class="form-control text-left"
            />
          </div>
          <div class="col-md-4">
            <Label>{{ "Pagamento" }}:</Label>
            <input
              v-model="despesa.dt_pagamento"
              type="date"
              class="form-control text-left"
            />
          </div>
        </div>
        <div class="mt-4 row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center mb-4">
            <Label class="">{{ "*Esta Conta já Foi Paga ?" }}</Label>
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center mb-4">
            <!-- <Label class="mb-4">{{ "*Esta Conta já Foi Paga ?" }}</Label> -->
            <!-- <b-form-checkbox> sim </b-form-checkbox>
            <b-form-checkbox> não </b-form-checkbox> -->
            <input type="radio" value="1" v-model="despesa.sit_conta" />
            <Label class="ml-2">sim</Label>
            <input
              type="radio"
              class="ml-2"
              value="0"
              v-model="despesa.sit_conta"
            />
            <Label class="ml-2">não</Label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <Label>{{ "conta" }}</Label>
            <multiselect
              v-model="despesa.conta"
              :options="contas"
              :custom-label="contaPessoa"
            >
            </multiselect>
          </div>
          <div class="col-md-4">
            <Label>{{ "Plano de Conta" }}</Label>
            <multiselect
              v-model="despesa.plano_conta"
              :options="planos"
              label="descricao"
            >
            </multiselect>
          </div>
          <div class="col-md-4">
            <Label>{{ "N_doc" }}</Label>
            <input
              v-model="despesa.num_conta"
              type="text"
              class="form-control text-left"
            />
          </div>
        </div>
        <div class="mt-4 row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <Label> Doc Fiscal </Label>
            <!-- <b-form-checkbox> sim </b-form-checkbox>
            <b-form-checkbox> não </b-form-checkbox> -->
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <input type="radio" v-model="despesa.doc_fisc_id" value="1" />
            <label for="" class="ml-2 mr-2">sim</label>
            <input type="radio" v-model="despesa.doc_fisc_id" value="0" />
            <label for="" class="ml-2">não</label>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-4">
            <Label>Empresa</Label>
            <multiselect :options="empresas"></multiselect>
          </div>
          <div class="col-md-4">
            <b-form-checkbox class="mb-2">
              Vincular a um aviso de Embarque
            </b-form-checkbox>
            <multiselect v-model="select_aviso" :options="avisos" label="identificacao"></multiselect>
          </div>
          <div class="col-md-4">
            <b-form-checkbox class="mb-2">
              referencia ao faturamento
            </b-form-checkbox>
            <input
              v-model="despesa.ref_fat"
              type="text"
              class="form-control text-left"
            />
          </div>
        </div>
        <div
          class="mt-4 mb-4 row row d-flex justify-content-center align-items-center"
        >
          <div class="col-md-12 text-center">
            <!-- criar logicas para aprece os outros inputs  -->
            <b-form-checkbox>Vincular a um fornecedor</b-form-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <Label>Observações:</Label>
            <textarea
              v-model="despesa.obs"
              name=""
              id=""
              cols="30"
              rows="1"
              class="form-control"
            ></textarea>
          </div>
          <div class="col-md-6">
            <Label>Ref.Localização(Documento):</Label>
            <input
              v-model="despesa.ref_loc"
              type="text"
              class="form-control text-left"
            />
          </div>
        </div>
        <div
          class="mt-4 mb-4 row d-flex justify-content-center align-items-center"
        >
          <div class="col-md-12 text-center">
            <b-form-checkbox>Repetir Registro</b-form-checkbox>
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <button class="btn btn-secondary" @click.prevent="setPost()">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import { http } from "../../../../helpers/easyindustriaapi/config";
export default {
  props: {
    veri_desp: { type: Boolean },
    array_pagamentos: { type: Array },
    listPlanos: { type: Array },
    listContas: { type: Array },
    objeto_prestacao: {type: Object}
  },
  components:{
    Multiselect
  },
  data() {
    return {
      despesa:{},
      select_aviso:null,
      vet_pagamentos:[],
      empresas: [
        {
          id: 1,
          nome: "empresa 1",
        },
      ],
      padroes: [
        {
          id: 1,
          nome: "Padrão1",
        },
        {
          id: 2,
          nome: "Padrão1",
        },
      ],
      Pagamentos: [
        {
          id: 1,
          nome: "dinheiro",
        },
      ],
      avisos:[
      {
          identificacao: null,
        }
      ],
      contas: [
        
      ],
      planos: [
        
      ],
      show_modal: false,
    };
  },
  created() {
    this.show_modal = this.veri_desp;
    this.vet_pagamentos = this.array_pagamentos
    this.planos = this.listPlanos
    this.contas = this.listContas
    this.avisos[0].identificacao = this.objeto_prestacao.identificacao

    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"))
  },
  mounted() {
     console.log("Mostradno Objeto prestação em Despesa")
     console.log(this.objeto_prestacao)
     console.log("Mosntrado vetor de avisos")
     console.log(this.avisos[0])
     this.select_aviso =this.avisos[0]
  },
  methods: {
    contaPessoa({pessoa}){
       console.log(pessoa)
       return pessoa.pes_apelido
    },
    back() {
      this.$emit("back");
    },
    setPost(){
      this.despesa.valor = this.new_convert_srting_float(this.despesa.valor)
      console.log("Estou no Set Post Despesas")
      console.log(this.despesa)
      this.despesa.aviso_embarque_id = this.objeto_prestacao.aviso_embarque_id
       let objeto = {...this.despesa}
      this.doPost(objeto)
    },
    async doPost(objeto) {
      // id
      // DescricaoUn
      // un_codigo
      // empresa_id

      objeto.empresa_id = this.currentEmpresa.id;
      // objeto.user_id = this.currentUser.id;
      // this.onLoader();
      try {
        let response = await http.post(
          "/despesa?empresa_id=" + this.currentEmpresa.id,
          objeto
        );
        if (response) {
          // this.offLoader();
          if (response.status === 200) {
            // this.getData();
            // this.inserindo = false;
            this.makeToast("success", "Registro incluído");
            this.$emit("getDespesa")

          }
        }
      } catch (error) {
        // this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
  },
};
</script>

<style>
</style>