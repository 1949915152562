<script>
import appConfig from "@/app.config";
import { http } from "../../../../helpers/easyindustriaapi/config";
import { authEasyMethods, notificationMethods } from "@/state/helpers";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import List from "./list";
import Insert from "./insert";
import Edit from "./edit";

export default {
  page: {
    // feita o ajuste
    title: "Aviso de Embarque",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: { Layout, PageHeader, Insert, List, Edit },
  data() {
    return {
      listContas:[],
      listPlanos:[],
      listPagamentos:[],
      avisso_carregados:false,
      ListAbastecimentos:[],
      listVeiculos:[],
      listRotas:[],
      listLocalEstoque: [],
      currentTabelas: {},
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Cadastro",
          href: "/",
          active: true,
        },
        {
          text: "Aviso de Embarque",
          href: "/carga_transporte/embarque",
          active: true,
        },
      ],

      //Unidade: undefined,
      //tabelas: [],
      grupos: undefined,
      tabelas: [],
      tabelas2: [],

      titleBody: "Grupos",
      editando: false,
      inserindo: false,
      showModal: false,
      prestacoes: [],
      listVendas: [],
      listPessoas: [],
    };
  },

  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa")); // para saber a emrpessa atual;
    // storage esta no navegador em aplucation;descricaogrupo
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    isHide() {
      return !this.inserindo && !this.editando;
    },
  },
  mounted() {
    this.getData();
    this.getData_vendas();
    this.getPessoas();
    this.getLocalEstoque();
    this.getRotas();
    this.getVeiculos();
    this.getAbastecimento();
    this.getPagamentos();
    this.getPlanos();
    this.getContas();
    //this.get_prestacao();
  },
  methods: {
    ...authEasyMethods,
    ...notificationMethods,
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    //
    async getData_vendas() {
      this.onLoader();
      this.carrega_funcs = false;
      try {
        let response = await http.get(
          "/venda?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.listVendas = response.data;
          console.log("Mostrando a Listagem de Vendas");
          console.log(this.listVendas);
          // this.get_tab_preco();
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    async getPlanos() {
      this.onLoader();
      this.carrega_funcs = false;
      try {
        let response = await http.get(
          "/plano_conta?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.listPlanos = response.data;
          console.log("Mostrando a Listagem de Planos contas");
          console.log(this.listPlanos);
          // this.get_tab_preco();
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    async getContas() {
      this.onLoader();
      this.carrega_funcs = false;
      try {
        let response = await http.get(
          "/conta?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.listContas = response.data;
          console.log("Mostrando a Listagem de Contas Correntes");
          console.log(this.listContas);
          // this.get_tab_preco();
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    async getPagamentos() {
      this.onLoader();
      this.carrega_funcs = false;
      try {
        let response = await http.get(
          "/tipopagamento?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.listPagamentos = response.data;
          console.log("Mostrando a Listagem de Pagamentos");
          console.log(this.listPagamentos);
          // this.get_tab_preco();
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none") {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    async getData() {
      this.onLoader();

      try {
        let response = await http.get(
          "/aviso-embarque?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          console.log("Monstranddo Avisos de Embrque");
          this.tabelas = response.data;
          console.log(this.tabelas);
             this.avisso_carregados = true
         
            this.offLoader();
        
        } else {
          this.tabelas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async getAbastecimento() {
      this.onLoader();

      try {
        let response = await http.get(
          "/abastecimento?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          console.log("Monstranddo Listagem Abastecimento");
          this.ListAbastecimentos = response.data;
          console.log(this.ListAbastecimentos);

          setTimeout(() => {
            this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.tabelas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async getPessoas() {
      this.onLoader();

      try {
        let response = await http.get(
          "/pessoa?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          console.log("Monstranddo Avisos de Embrque");
          this.listPessoas = response.data;
          // console.log(this.tabelas)

          setTimeout(() => {
            this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.listPessoas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async getRotas() {
      this.onLoader();

      try {
        let response = await http.get(
          "/rota?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          console.log("Monstranddo Avisos de Embrque");
          this.listRotas = response.data;
          // console.log(this.tabelas)

          setTimeout(() => {
            this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.listRotas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async getVeiculos() {
      this.onLoader();

      try {
        let response = await http.get(
          "/veiculo?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          console.log("Monstranddo Avisos de Embrque");
          this.listVeiculos = response.data;
          // console.log(this.tabelas)

          setTimeout(() => {
            this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.listVeiculos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async getLocalEstoque() {
      this.onLoader();

      try {
        let response = await http.get(
          "/localestoque?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          console.log("Monstranddo Locais Estoque");
          this.listLocalEstoque = response.data;
          console.log(this.listLocalEstoque);

          setTimeout(() => {
            this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.listLocalEstoque = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async get_prestacao() {
      this.onLoader();

      try {
        let response = await http.get(
          "/prestacaoConta?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.prestacoes = response.data;
          // console.log(this.prestacoes)
          setTimeout(() => {
            this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.prestacoes = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    setTabelas(tabelas) {
      this.currentTabelas = tabelas;
      //console.log(this.currentTabelas)
    },
    insert() {
      this.showModal = true;
      this.inserindo = !this.inserindo; // recebe true;
    },
    edit(oldTabelas) {
      this.setTabelas(oldTabelas);
      this.editando = !this.editando;
    },

    async doPost(embarque) {
      // id
      // DescricaoUn
      // un_codigo
      // empresa_id

      embarque.empresa_id = this.currentEmpresa.id;
      // embarque.user_id = this.currentUser.id;
      this.onLoader();
      try {
        let response = await http.post(
          "/aviso-embarque?empresa_id=" + this.currentEmpresa.id,
          embarque
        );
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            this.getData();
            this.inserindo = false;
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },

    async doPost_prestacao(prestacao) {
      // id
      // DescricaoUn
      // un_codigo
      // empresa_id

      ///prestacao.empresa_id = this.currentEmpresa.id;
      // prestacao.user_id = this.currentUser.id;
      console.log("entrei aqui no post do index");
      this.onLoader();
      try {
        let response = await http.post(
          "/prestacaoConta?empresa_id=" + this.currentEmpresa.id,
          prestacao
        );
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            this.getData();
            this.inserindo = false;
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async doPostabastecimento(objeto) {
      console.log("entrei no index post de abastecimentos");
      //console.log(objeto.vet_abastecimento)
      //  isso objeto te as prestações com aviso de emarque id
      // masi o vetor de abastecimentos;

      this.onLoader();
      try {
        let response = await http.post(
          "/abastecimento?empresa_id=" + this.currentEmpresa.id,
          objeto
        );
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            this.getData();
            this.inserindo = false;
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async doPut_prestacao(prestacao) {
      console.log("entramos na put do index");
      //console.log(prestacao)
      this.onLoader();

      try {
        let response = await http.put(
          "/prestacaoConta/" +
            prestacao.id +
            "?empresa_id=" +
            this.currentEmpresa.id,
          prestacao
        );
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            this.makeToast("success", "Registro alterado");
            this.getData();
            this.editando = false;
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async post_viagem(objeto) {
      console.log("cheguei no post viagem do index");
      console.log();
      // console.log(objeto)
      this.onLoader();
      try {
        let response = await http.post(
          "/acompanhamento?empresa_id=" + this.currentEmpresa.id,
          objeto
        );
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            if(objeto.obj_atu != null && objeto.obj_atu != undefined && objeto.obj_atu != ''){
              this.doPut_prestacao(objeto.obj_atu)
            }
            this.getData();
            this.inserindo = false;
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async put_viagem(objeto) {
      console.log("cheguei no post viagem do index");
      // console.log(objeto)
      this.onLoader();
      try {
        let response = await http.put(
          "/acompanhamento/" +objeto.id+"?empresa_id=" + this.currentEmpresa.id,
          objeto
        );
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            if(objeto.obj_atu != null && objeto.obj_atu != undefined){
              this.doPut_prestacao(objeto.obj_atu)
            }
            this.getData();
            this.inserindo = false;
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async doPut(embarque) {
      this.onLoader();

      try {
        let response = await http.put(
          "/aviso-embarque/" +
            embarque.id +
            "?empresa_id=" +
            this.currentEmpresa.id,
          embarque
        );
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            this.makeToast("success", "Registro alterado");
            this.getData();
            this.editando = false;
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async doDelete(embarque) {
      // console.log(embarque);
      this.onLoader();

      try {
        let response = await http.delete(
          "/aviso-embarque/" +
            embarque.id +
            "?empresa_id=" +
            this.currentEmpresa.id,
          embarque
        );
        if (response) {
          if (response.status === 200) {
            this.getData();
            this.offLoader();
            this.makeToast("warning", "Registro excluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast(
              "danger",
              "A uma prestação ou Acopahamento de Viagem Associado ao Aviso de Embarque"
            );
          }
        }
      }
    },
    close_modal() {
      // fecha o modal ao clicar;
      this.showModal = false;
    },
    back() {
      this.inserindo = false;
      this.editando = false;
      this.titleBody = "Embarque";
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6"></div>
              <div class="col-sm-12 col-md-6 text-md-right">
                <button
                  @click="insert()"
                  v-if="isHide"
                  type="button"
                  class="btn btn-success"
                >
                  + Incluir Cadastro de Embarque
                </button>
                <button
                  @click="back()"
                  v-if="!isHide"
                  class="btn btn-secondary"
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div v-if="!isHide" class="card-body">
            <Edit
              v-if="editando"
              :empresa="currentEmpresa"
              :oldTabelas="currentTabelas"
              :listGrupos2="tabelas"
              :ola="currentTabelas"
              @newTitle="changeHomeTitle"
              @doPut="doPut"
              :listPessoas="listPessoas"
              :listLocalEstoque="listLocalEstoque"
              :listRotas="listRotas"
              :listVeiculos="listVeiculos"
              :listVendas="listVendas"
            />
            <Insert
              v-if="inserindo"
              :listGrupos="tabelas"
              :listGrupos2="tabelas"
              :currentEmpresa="currentEmpresa"
              @newTitle="changeHomeTitle"
              @doPost="doPost"
              :showModal="showModal"
              :listVendas="listVendas"
            />
          </div>
          <!-- <b-modal
            v-model="showModal"
            id="modal-xl"
            size="xl"
            title="Tipo de Tabela de preços"
            hide-footer
          >
            <div class="form-row form-group" role="group">
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_sigla_id" class="col-form-label">Nome</label>
                  <div class="col">
                    <input
                      v-model="tabelas.ttp_nome"
                      class="form-control text-leftt"
                      type="text"
                      placeholder="digite a Sigla"
                      id="sigla"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-10">
              <button class="btn btn-light" @click="close_modal">Gravar</button>
            </div>
          </b-modal> -->
          <List
             v-if="avisso_carregados"
            :currentEmpresa="currentEmpresa"
            :listTabelas="tabelas"
            :hide="isHide"
            :ListAbastecimentos="ListAbastecimentos"
            :listPagamentos="listPagamentos"
            :listPlanos="listPlanos"
            :listContas="listContas"
            @edit="edit"
            @newTitle="changeHomeTitle"
            @setTabelas="setTabelas"
            @doDelete="doDelete"
            @doPost_prestacao="doPost_prestacao"
            @doPut_prestacao="doPut_prestacao"
            @doPostabastecimento="doPostabastecimento"
            @post_viagem="post_viagem"
            @put_viagem="put_viagem"
            @getData="getData"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>