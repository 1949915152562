<script>
//import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
// import Venda from './vendaDetalhe/venda.vue';
import VendaDetalhe from "./vendaDetalhe/venda.vue";
import { http } from "../../../../helpers/easyindustriaapi/config";

export default {
  props: {
    oldTabelas: { type: Object },
    listGrupos2: { type: Array },
    listPessoas: { type: Array },
    listLocalEstoque: { type: Array },
    listVendas: { type: Array },
    listRotas: { type: Array },
    listVeiculos: { type: Array },
    /// parentSelected: { type: Object, required: true }
    ola: { type: Object },
  },
  components: {
    Multiselect,
    // Venda,
    VendaDetalhe,
  },
  data() {
    return {
      select_ass_venda: null,
      vet_pessoas: [],
      loader: { get: false },
      titleBody: "Alterar Grupo",
      tabelas: {
        ttp_nome: null,
      },
      motorista: [
        {
          id: 1,
          nome: "motorista1",
        },
      ],
      veiculos: [
        {
          id: 1,
          nome: "veiculos1",
        },
      ],
      rotas: [],
      insert_embarque: {
        motorista_id: null,
        veiculo_id: null,
        rota_id: null,
        identificacao: null,
        notas: null,
        data_carregamento: null,
        data_previsao: null,
        obs: null,
      },
      select_motorista: null,
      select_rota: null,
      select_veiculos: null,
      vendas_aviso: [],
      venda_detalhe: null,
      vendas_aviso2: [],
      vendas: [],
      show_vendas: false,
    };
  },
  validations: {
    tabelas: {
      ttp_nome: { required },
    },
  },
  created() {
    this.vet_pessoas = this.listPessoas;
    this.rotas = this.listRotas;
    this.veiculos = this.listVeiculos;
    this.vendas = this.listVendas;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
  },
  mounted() {
    this.setNewTitle();
    console.log("Mostrano Obeto Edit aviso de embarque");
    console.log(this.ola);
    console.log(this.oldTabelas);
    this.insert_embarque = this.ola;
    this.vendas_aviso = this.oldTabelas.vendas;
    this.motorista = this.listPessoas.filter((p) => p.pes_motorista === -6);
    this.preenche_moto_rota();
  },
  methods: {
    show_all_vendas(event) {
      console.log(event);

      this.show_vendas = event == true ? true : false;
    },
    get_valor_venda2(vet_intens) {
      let array_itens = [...vet_intens];
      let soma = 0;
      array_itens.forEach((item) => {
        let valor = item.valor * item.quantidade;
        soma += valor;
        console.log("itens" + item);
      });

      return this.formatterCurrBR(soma);
    },
    seleciona_pre_pedido2(item) {
      console.log(item.select_item);

      if (item.select_item) {
        this.vendas_aviso2.push({ ...item });
      } else {
        this.vendas_aviso2 = this.vendas_aviso2.filter((v) => v.id != item.id);
      }

      console.log(
        "Mostrando o vetor de Vendas Para ser Associadao ao Aviso de Embarque"
      );
      console.log(this.vendas_aviso2);

      this.insert_embarque.vendas_aviso2 = this.vendas_aviso2;
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    async doPost(embarque) {
      // id
      // DescricaoUn
      // un_codigo
      // empresa_id

      embarque.empresa_id = this.currentEmpresa.id;
      // embarque.user_id = this.currentUser.id;
      // this.onLoader();
      try {
        let response = await http.post(
          "/aviso-embarque/dessosssociarVenda?empresa_id=" +
            this.currentEmpresa.id,
          embarque
        );
        if (response) {
          // this.offLoader();
          if (response.status === 200) {
            // this.getData();
            this.inserindo = false;
            this.vendas_aviso = this.vendas_aviso.filter((v)=>v.id !== embarque.id)
            this.makeToast("success", "Venda Desassociada");
          }
        }
      } catch (error) {
        // this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    post_dessassociar(objeto) {
      console.log("Monstrando Objeto para desassociar venda");
      console.log(objeto);
      this.doPost(objeto);
    },
    preenche_moto_rota() {
      let objeto_embarque = { ...this.insert_embarque };
      let rota_id = objeto_embarque.rota_id;
      console.log(rota_id);
      let motorista_id = objeto_embarque.motorista_id;
      let veiculo_id = objeto_embarque.veiculo_id;

      this.select_motorista = this.listPessoas.find(
        (p) => p.id == motorista_id
      );
      this.select_rota = this.rotas.find((r) => r.id == rota_id);
      this.select_veiculos = this.veiculos.find((v) => v.id == veiculo_id);
    },
    get_venda(objeto) {
      console.log("Mostrando Obejto Venda");
      console.log(objeto);
      this.show_venda = true;
      this.venda_detalhe = { ...objeto };
    },
    seleciona_pre_pedido(item) {
      console.log(item.select_item);

      if (item.select_item) {
        // this.vendas_aviso.push({...item})
      } else {
        // this.vendas_aviso = this.vendas_aviso.filter((v)=>v.id != item.id)
      }

      console.log(
        "Mostrando o vetor de Vendas Para ser Associadao ao Aviso de Embarque"
      );
      console.log(this.vendas_aviso);
    },
    get_valor_venda(vet_intens) {
      let array_itens = [...vet_intens];
      let soma = 0;
      array_itens.forEach((item) => {
        let valor = item.valor * item.quantidade;
        soma += valor;
        console.log("itens" + item);
      });

      return this.formatterCurrBR(soma);
    },
    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      await this.$v.$touch();

      this.validatesFields().then(() => {
        this.setPut();
      });
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPut() {
      console.log("Gravou");
      console.log(this.insert_embarque);
      this.$emit("doPut", this.insert_embarque);
    },
    seleciona_moto(event) {
      //console.log(event)
      this.insert_embarque.motorista_id = event.id;
    },
    seleciona_rota(event) {
      this.insert_embarque.rota_id = event.id;
      //console.log(event)
    },
    seleciona_veiculo(event) {
      this.insert_embarque.veiculo_id = event.id;
      ///console.log(event)
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row mb-4">
        <div class="col-md-4">
          <!-- insert_embarque: {
        motorista: null,
        veiculo: null,
        rota: null,
        identificacao: null,
        notas: null,
        data_carregamento: null,
        data_previsao: null,
        obs: null,
      }, -->
          <Label>{{ "*Motorista" }}</Label>
          <Multiselect
            @select="seleciona_moto($event)"
            v-model="select_motorista"
            :options="motorista"
            label="pes_apelido"
          >
          </Multiselect>
        </div>
        <div class="col-md-4">
          <Label>{{ "*Veiculos" }}</Label>
          <Multiselect
            @select="seleciona_veiculo($event)"
            v-model="select_veiculos"
            :options="veiculos"
            label="nomeRazao"
          >
          </Multiselect>
        </div>
        <div class="col-md-4">
          <Label>{{ "*Rota/Destino" }}</Label>
          <Multiselect
            @select="seleciona_rota($event)"
            v-model="select_rota"
            :options="rotas"
            label="nome"
          >
          </Multiselect>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-3">
          <Label>{{ "*Identificação" }}</Label>
          <input
            v-model="insert_embarque.identificacao"
            type="text"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-3">
          <Label>{{ "Notas" }}</Label>
          <input
            v-model="insert_embarque.notas"
            type="text"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-3">
          <Label>{{ "previsão de Carregamento" }}</Label>
          <input
            v-model="insert_embarque.data_carregamento"
            type="date"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-3">
          <Label>{{ "previsão de Entrega" }}</Label>
          <input
            v-model="insert_embarque.data_previsao"
            type="date"
            class="form-control text-left"
          />
        </div>
      </div>

      <div class="row mt-4 d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <!-- <b-form-checkbox> Pedidos </b-form-checkbox> -->
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pedido variant="light">pedido</b-button>
          </b-card-header>
          <b-collapse id="pedido" accordion="pedido-accordion" role="tabpanel">
            <!-- {{ "INSERIR UM PEDIO PARA ESTE MOTORISTA" }} -->
            <div class="row">
              <div class="col-md-12">
                <b-form-checkbox @change="show_all_vendas($event)"
                  >Mostrar todas as Vendas</b-form-checkbox
                >
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-4"
                v-for="(item, index) in vendas_aviso"
                :key="(index = item.id)"
              >
                <div class="card">
                  <div class="card-body bg-light">
                    <b-form-checkbox
                      v-model="item.select_item"
                      @change="seleciona_pre_pedido(item)"
                      :value="index"
                    >
                      {{ "Pedido n° :" }}-{{ index }} -
                      <p class="badge badge-success">
                        {{ "Valor :" }} {{ get_valor_venda(item.venda_itens) }}
                      </p>
                    </b-form-checkbox>
                    <button
                      class="btn btn-success"
                      @click.prevent="get_venda(item)"
                      v-b-modal.modal-venda
                    >
                      Ver
                    </button>
                    <button
                      class="btn btn-danger ml-2"
                      @click.prevent="post_dessassociar(item)"
                    >
                      Desassociar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row mt-2"
              v-if="vendas_aviso.length == 0 || show_vendas"
            >
              <!-- {{ "INSERIR UM PEDIO PARA ESTE MOTORISTA" }} -->
              <div
                class="col-md-4"
                v-for="(item, index) in vendas"
                :key="(index = item.id)"
              >
                <div class="card">
                  <div class="card-body">
                    <b-form-checkbox
                      v-model="item.select_item"
                      @change="seleciona_pre_pedido2(item)"
                      :value="index"
                    >
                      {{ "Pedido n° :" }}-{{ index }} - {{ "Valor :" }}
                      {{ get_valor_venda2(item.venda_itens) }}
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-modal
            size="xl"
            hide-footer
            id="modal-venda"
            ref="modal-venda"
            :title="'Detalhes da Venda'"
            title-class="font-18"
          >
            <VendaDetalhe
              :venda_detalhe="venda_detalhe"
              :vet_pessoas="vet_pessoas"
              :listLocalEstoque="listLocalEstoque"
            />
          </b-modal>
        </div>
      </div>
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.iten variant="light">Observação</b-button>
      </b-card-header>
      <b-collapse id="iten" accordion="pedido-accordion" role="tabpanel">
        <div class="row mt-4 d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <textarea
              v-model="insert_embarque.obs"
              name=""
              id=""
              cols="30"
              rows="10"
              class="form-control text-left"
            ></textarea>
          </div>
        </div>
      </b-collapse>
      <div class="row mt-4 d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <button class="btn btn-secondary" @click="setPut()">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>