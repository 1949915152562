<template>
  <div class="row d-flex justify-content-center align-items-center">
    <div class="col-md-12 text-center">
      <div class="card bg-light">
        <div class="card-body">
          <p class="badge badge-success">
            {{ get_pessoas(venda_objeto.cliente_id) }}
          </p>
        </div>
        <div class="card-body">
          <b-table
            hover
            :items="venda_objeto.venda_itens"
            id="doc-itens"
            :fields="fields"
          >
            <template #cell(acoes)="row">
              <div>
                <button
                  class="btn btn-success"
                  @click.prevent="ver_estoque(row.item)"
                >
                  Estoque
                </button>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div class="col-md-12 text-center" v-if="show_estoque">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.estoque variant="light">Estoque</b-button>
      </b-card-header>
      <b-collapse id="estoque" accordion="pedido-accordion" role="tabpanel">
        <div
          class="row d-flex justify-content-center align-items-center mt-1"
          v-if="
            local_estoque_selectd != -1 &&
            local_estoque_selectd != null &&
            local_estoque_selectd != undefined
          "
        >
          <div class="col-md-12 text-center">
            <div class="card">
              <div class="card-body bg-light">
                <h2>Quantidade Atual {{ quantide_atual }}</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12 mb-1">
            <Label>Local Estoque</Label>
            <select
              class="form-control"
              id="exampleFormControlSelect1"
              v-model="local_estoque_selectd"
              @change="seleciona_local($event)"
            >
              <!-- criei o produto_id so para receber o id , mas oque é utilizado é o produto.descriçaõ -->
              <option value="-1">selecione um item</option>
              <option
                v-for="(item, index) in locais"
                :key="index"
                :value="item.id"
              >
                {{ item.descricao }}
              </option>
            </select>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { http } from "../../../../../helpers/easyindustriaapi/config";
export default {
  props: {
    venda_detalhe: { type: Object },
    vet_pessoas: { type: Array },
    listLocalEstoque: { type: Array },
  },
  data() {
    return {
      quantide_atual: null,
      local_estoque_selectd: null,
      locais: [],
      show_estoque: false,
      array_pessoas: [],
      venda_objeto: null,
      fields: [
        {
          label: "ID",
          key: "produto_id",
          sortable: true,
          tdClass: "text-left",
          thClass: "text-left",
          variant: "light",
        },
        {
          label: "Nome",
          key: "nome",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Preço",
          key: "preco",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
          variant: "light",
        },
        {
          label: "Quantidade",
          key: "quantidade",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Valor",
          key: "valor",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
          variant: "light",
        },
        // { label: "Tag", key: "grupo_tag", sortable: true, tdClass: 'text-left', thClass: 'text-center' },
        //{ label: "ID_Empresa", key: "empresa_id", sortable: false, thClass: 'text-center' },
        // { label: 'Preço', key: 'preco', sortable: false, tdClass: 'text-right', thClass: 'text-center',
        //   formatter: "formatCurrency"},
        {
          label: "Ações",
          key: "acoes",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      objeto_estoque: null,
    };
  },
  created() {
    this.venda_objeto = this.venda_detalhe;
    this.array_pessoas = this.vet_pessoas;
    this.locais = this.listLocalEstoque;
  },
  methods: {
    seleciona_local(event) {
      console.log(event.target.value);
      let objeto;
      objeto = this.locais.find((l) => l.id == event.target.value);
      console.log(objeto);
      console.log("mostrano os estoque desse locl de estoque");
      this.objeto_estoque.produto_id;
      let soma = 0;
      objeto.estoque.forEach((item) => {
        if (item.produto_id == this.objeto_estoque.produto_id) {
          soma = soma + item.qtdenew;
        }
      });

      console.log(objeto.estoque);
      console.log("Mostrnado valor Ataul de estoque do Produot selecioando ");
      console.log(soma);
      this.quantide_atual = soma;
    },
    async doPost(embarque) {
      // id
      // DescricaoUn
      // un_codigo
      // empresa_id

      embarque.empresa_id = this.currentEmpresa.id;
      // embarque.user_id = this.currentUser.id;
      this.onLoader();
      try {
        let response = await http.post(
          "/showEstoque/?empresa_id=" + this.currentEmpresa.id,
          embarque
        );
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            this.getData();
            this.inserindo = false;
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    get_pessoas(id) {
      let res;
      res = this.array_pessoas.find((p) => p.id == id);
      return res.pes_apelido;
    },
    ver_estoque(objeto) {
      console.log(objeto);
      this.objeto_estoque = { ...objeto };
      this.show_estoque = !this.show_estoque;
      this.local_estoque_selectd = -1
    },
  },
};
</script>

<style>
</style>